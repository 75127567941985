import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildCustomExportUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import { CustomExportInfo } from '../types';

export const getCustomExports = (
    actionName?: string,
    processResultFunction?: Function,
): Observable<{
    result: CustomExportInfo[];
    actionName?: string;
    processResultFunction?: Function;
}> => {
    const url = buildCustomExportUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<{ CustomExport: any[] }>) => {
            const result = convertToCamel<CustomExportInfo[]>(response.CustomExport);
            return observableOf({
                result,
                actionName,
                processResultFunction,
            });
        }),
    );
};
